<template>
  <div id="about-modal" class="modal bg-center bg-cover"
       :style="{'background-image':'url(/images/background/backdrop_3.jpg)'}">
    <div class="modal-content">

      <div class="relative h-16 w-16 ...">
        <span class="closeAbout absolute left-0 top-0 h-16 w-16 ml-2" v-on:click="clickedClose"><svg
            xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
</svg></span>
      </div>

      <div class="px-10 py-12 bg-white rounded-lg shadow-lg ml-3 mr-3">

        <!--        <div class="relative h-32 w-32 ...">
                  <div class="absolute top-0 right-0 h-16 w-16 ..."><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg></div>
                </div>-->

        <div class="text-gray-800 text-sm md:text-2xl">
          <p>Welcome to Phantom Sonix, home of sound designer and sound recordist Michal Kuligowski. With experience,
            good understanding and proactive interest in various aspects of sound for moving image and beyond Michal is
            capable of delivering sonic layer for your production whether it being on set or in the studio. </p>
          <br/>
          <div class="hero container max-w-screen-lg mx-auto pb-10">
            <img src="photos/animorph-michal.jpg" alt="avatar pic" class="mx-auto rounded-lg shadow-lg">
          </div>

          <p>I started working with sound professionally in 2008. First as live sound engineer to then move to editorial
            and location sound after my studies at Ravensbourne college in 2011-14. I have since worked as sound
            designer and editor as well as production sound mixer & recordist for variety of formats ranging from film
            and television to augmented and virtual reality. I am also a funding member and person responsible for all
            things audio at <a href="https://animorph.coop/" target="_blank">Animorph.coop</a>.</p>

        </div>
      </div>
    </div>
    <br/>
  </div>
</template>

<script>
export default {

  data() {
    return {}
  },
  methods: {
    clickedClose() {
      this.$emit('closed')

    }
  },
  mounted() {
    window.addEventListener('keyup', this.clickedClose)
  },
}
</script>

<style scoped>


.modal {
  /*
    display: none;
  */
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  pointer-events: auto;
  background-color: #fff;
}


.closeAbout {
  color: #ccc;
  float: right;
  font-size: 3rem;
  left: 0;
}

.closeAbout:hover,
.closeAbout:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


</style>