<template>
  <div id="welcome" class="animated fadeIn fixed z-50 pin overflow-hidden 2xl:focus-within:bg-gray-600 flex z-90">

    <div
        class="animated fadeInUp fixed shadow-inner max-w-md md:relative pin-b pin-x align-top m-auto justify-end md:justify-center p-8
        bg-white rounded-full h-96 w-96
        md:shadow flex flex-col
        hover:bg-black">
      <div class="rounded-lg text-center" v-on:click="clickedButton">

      <h4 class="text-4xl font-sans md:leading-loose text-gray-800 md:mt-8 mb-4">Phantom Sonix</h4>

      <button
              class="bg-grey-800 border-b-2 border-black-900 ml-2 text-gray-900 hover:text-white font-bold py-4 px-6 rounded ">
        Press to enter
      </button>
      <br/>

      <br/>
      <p class="text-xl leading-normal mb-8 text-gray-700">
        Keep the sound on!
      </p>

    </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },

  methods: {
    clickedButton() {
      var welcome = document.getElementById('welcome')
      welcome.style.opacity = 0
      setTimeout(() => {
        this.$emit('triggered')
        this.$store.dispatch("setEntered", "true");
        this.$store.dispatch("setAudio", "true");
      }, 450)

    },

  },

  mounted() {
    window.addEventListener('keyup', this.clickedButton)
    //window.addEventListener('ontouchend', this.clickedButton)
  },
}
</script>

<style scoped>

#welcome {

  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  opacity: 1;
  -webkit-transition: opacity 0.4s linear;
  -moz-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  -ms-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
  float: left;
  overflow: hidden;
}
</style>