<template>
  <div id="about-modal" class="modal bg-center bg-cover"
       :style="{'background-image':'url(/images/background/backdrop_3.jpg)'}">
    <div class="modal-content">

      <div class="relative h-16 w-16 ...">
        <span class="closeAbout absolute left-0 top-0 h-16 w-16 ml-2" v-on:click="clickedClose"><svg
            xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
</svg></span>
      </div>

      <div class="px-10 py-12 bg-white rounded-lg shadow-lg ml-2 mr-2">
        <h3 class="text-gray-800 text-lg md:text-3xl">Let's discover together!</h3>
        <br/>
        <div class="text-gray-800 text-sm md:text-2xl">
        <p>Reach me via email at <a href="mailto:michal@phantomsonix.com" class="py-1 md:py-3 pl-1 text-grey-900 no-underline hover:text-red-800 border-b-4 border-gray-900 hover:border-red-800">michal@phantomsonix.com</a></p>
        </div>
      </div>
    </div>
    <br/>
  </div>
</template>

<script>
export default {

  data() {
    return {

    }
  },
  methods: {
    clickedClose() {
      this.$emit('closed')

    }
  },
  mounted() {
    window.addEventListener('keyup', this.clickedClose)
  },
}
</script>

<style scoped>


.modal {
  /*
    display: none;
  */
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  pointer-events: auto;
  background-color: #fff;
}


.closeAbout{
  color: #ccc;
  float: right;
  font-size: 3rem;
  left: 0;
}

.closeAbout:hover,
.closeAbout:focus
{
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>